.form-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.form-container input[type="text"],
.form-container input[type="tel"],
.form-container input[type="email"],
.form-container input[type="url"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-container button {
  background-color: #ff9900;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.form-container button:hover {
  background-color: #e68a00;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
