.home-container {
  text-align: center;
  padding: 30px;
}

.logo {
  max-width: 350px;
  margin-bottom: 0px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
