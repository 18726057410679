
.records-main-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}


.record-wrapper {
  display: flex;
  justify-content: space-between; 
  align-items: flex-end; 
  margin-bottom: 10px; 
  flex-wrap: nowrap; 
  width: 700px;
}
.records-container {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  flex-grow: 1;
  position: relative; 
}



.edit-button:hover {
  background-color: #e68a00;
}

.edit-save, .cancel-save {
  background-color: #ff9900;
}

.edit-save:hover, .cancel-save:hover {
  background-color: #e68a00;
}

.cancel-save {
  margin-left: 10px;
}
.editorbuttons {
  display: flex;
}
ul {
  list-style-type: none; 
  padding: 0;
  margin: 0;
}
input[type="text"],
input[type="email"],
input[type="url"] {
  
  margin: 5px 0;
  padding: 8px;
  display: block; 
  width: 95%; 
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 380px;
}

.goAddCardButton:hover {
  background-color: #e68a00;
}

.goAddCardButton {
  background-color: #ff9900;
}

.delete-button {
  background-color: #ff9900;
  margin-left: 10px;
}

.delete-button:hover {
  background-color: #e68a00;
}
.delete-button-confirm {
  background-color: #f10000;
  margin-left: 10px;
}

.delete-button-confirm:hover {
  background-color: #d90000;
}

.cancel-delete-button {
  margin-left: 10px;
  background-color: #ff9900;
}

.cancel-delete-button:hover {
  background-color: #e68a00;
}

.edit-button, .copy-link-button {
  background-color: #ff9900;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px; 
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 36px; 
  height: 36px; 
}

.copy-link-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.edit-button {
  
  margin-left: 20px; 
  margin-bottom: 11px; 
}
